import { render, staticRenderFns } from "./SectionTeam.vue?vue&type=template&id=31872d1b&scoped=true&"
import script from "./SectionTeam.vue?vue&type=script&lang=js&"
export * from "./SectionTeam.vue?vue&type=script&lang=js&"
import style0 from "./SectionTeam.vue?vue&type=style&index=0&id=31872d1b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31872d1b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,AtomsCommonAtomVerticalLine: require('/app/components/atoms/common/AtomVerticalLine.vue').default,OrganismsPortfolioOrganismTeam: require('/app/components/organisms/portfolio/OrganismTeam.vue').default})
